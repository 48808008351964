/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-useless-escape */
import { LoanStage } from "@toorak/tc-common-fe-sdk";
import {
  getLoanTypeFromLoanId,
  LoanStatusEnum
} from "@toorak/tc-common-util-sdk";
import moment from "moment";
import isEmail from "validator/lib/isEmail";
import { getLoanType } from "../config/config";
import { EvaluationResultStore } from "../stores/EvaluationResults/EvaluationResults.reducer";
import { isLATUser } from "./AccessManagement";
import { getCookie } from "./cookies";
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-restricted-globals */

export function sanitizeNumericInput(text: string, keepMoreThanOneLeadingZeros?: boolean) {
  if (text == null) {  // Check for null or undefined
    return "";
  }

  // Convert the input to a string
  let valueToReturn = String(text).replace(/\D/g, "");

  // Conditionally replace multiple leading zeros if not allowed
  if (!keepMoreThanOneLeadingZeros) {
    valueToReturn = valueToReturn.replace(/^00+/, "0");
  }

  // Ensure the length does not exceed 9 digits
  if (valueToReturn.length > 9) {
    valueToReturn = valueToReturn.slice(0, 9);
  }

  return valueToReturn;
}

export function sanitizePhoneInput(text: string, allowMinus?: boolean) {
  let valueToReturn;
  if (allowMinus) {
    valueToReturn = text.replace(/\D/g, "");
  } else {
    valueToReturn = text.replace(/\D/g, "").replace(/^00+/, "0");
  }
  if (valueToReturn.length > 10) {
    valueToReturn = valueToReturn.slice(0, 10);
  }
  return valueToReturn;
}

export function sanitizeNumber(text: string) {
  return text.replace(/\D/g, "").replace(/^00+/, "0");
}

export function sanitizePercentagWithMinue(text: string) {
  return text.replace("^-?d*.?d+$", "");
}

export const sanitizePhoneNumber = (text: string) => {
  const onlyDigits = text?.replace(/[^0-9]/g, "");
  let result = text;
  if (onlyDigits && onlyDigits.length > 16) {
    const specialCharsCount = text.length - onlyDigits.length || 0;
    result = text.slice(0, 16 + specialCharsCount);
  }

  if (result) {
    return result
      .replace(/[^0-9 ()+-\s]/g, "")
      .replace(/^\./, "")
      .replace(/^\-/, "")
      .replace(/^\s/, "")
      .replace(/\s\s+/g, " ");
  }
  return "";
};
export const isValidEmailFormat = (text: string) => {
  return isEmail(`${text}`); // if anything other than string is passed, it throws error
  // return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(text);
};

export const isValidPhoneNumber = (text: string) => {
  // return /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(text);
  return /^[0-9(\[\]\)\-\+\s]{6,16}$/.test(text);
};
export const isValidDate = (value: any) => {
  if (value) {
    const date = new Date(value);
    const timeStamp = date.getTime();
    return !isNaN(timeStamp);
  }
  return false;
};
export function isValidPercentage(text: string, blockNegative?: boolean) {
  let textToCheck = text;
  if (text && text.search("-") >= 0) {
    if (blockNegative) {
      return false;
    }
    textToCheck = text.replace("-", "");
  }
  return /^\d+(\.\d+)?%{0,1}$/.test(textToCheck);
}
export function cleanPercentage(text: string) {
  if (!isNullOrUndefined(text)) {
    return (
      text
        .replace(/[^0-9.%]/g, "")
        // eslint-disable-next-line no-plusplus, no-param-reassign
        .replace(
          /\./g,
          (
            (i) => (m: string) =>
              !i++ ? m : ""
          )(0)
        )
        // eslint-disable-next-line no-plusplus, no-param-reassign
        .replace(
          /[%]/g,
          (
            (i) => (m: string) =>
              !i++ ? m : ""
          )(0)
        )
        .replace(/^\./, "")
        .replace(/^$%/, "")
        .replace(/\.(\d\d\d\d)\d?$/, ".$1")
    );
  }
  return text;
}

export function cleanPercentageV2(text: string) {
  if (!isNullOrUndefined(text)) {
    return text.replace(/[%]/g, "");
  }
  return text;
}

export function cleanPercentageWithMinus(text: string) {
  if (!isNullOrUndefined(text)) {
    return (
      text
        .replace(/[^[0-9].%]/g, "")
        // eslint-disable-next-line no-plusplus, no-param-reassign
        .replace(
          /\./g,
          (
            (i) => (m: string) =>
              !i++ ? m : ""
          )(0)
        )
        // eslint-disable-next-line no-plusplus, no-param-reassign
        .replace(
          /[%]/g,
          (
            (i) => (m: string) =>
              !i++ ? m : ""
          )(0)
        )
        .replace(/^\./, "")
        .replace(/^$%/, "")
        .replace(/\.(\d\d)\d?$/, ".$1")
    );
  }
  return text;
}

export function cleanNumericNegativePercentage(text: string) {
  if (!isNullOrUndefined(text)) {
    return (
      text
        .replace(/[a-zA-Z ]*$/, "")
        .replace(/[^[0-9].%]/g, "")
        // eslint-disable-next-line no-plusplus, no-param-reassign
        .replace(
          /\./g,
          (
            (i) => (m: string) =>
              !i++ ? m : ""
          )(0)
        )
        // eslint-disable-next-line no-plusplus, no-param-reassign
        .replace(
          /[%]/g,
          (
            (i) => (m: string) =>
              !i++ ? m : ""
          )(0)
        )
        .replace(/^\./, "")
        .replace(/^$%/, "")
      // .replace(/\.(\d\d)\d?$/, ".$1")
    );
  }
  return text;
}
export const cleanDecimal = (text: any) => {
  if (typeof text === "string") {
    // ^[0-9]{1,11}(?:\.[0-9]{1,3})?$
    return (
      text
        .replace(/[^0-9.$]/g, "")
        // eslint-disable-next-line no-plusplus, no-param-reassign
        .replace(
          /[$]/g,
          (
            (i) => (m: string) =>
              !i++ ? m : ""
          )(0)
        )
        // eslint-disable-next-li ne no-plusplus, no-param-reassign
        .replace(
          /[.]/g,
          (
            (i) => (m: string) =>
              !i++ ? m : ""
          )(0)
        )
        .replace(/[$]/g, (c, i) => (i !== 0 ? "" : c))
        .replace(/^\./, "")
        .replace("$", "")
      // .replace(/\.(\d\d)\d?$/, ".1")
    );
  }
  return text;
};
export const isValidDecimal = (text: string) => {
  if (isEmptyValue(text)) {
    return false;
  }
  return /^[0-9]\d*(\.\d+)?$/.test(text);
};
export const cleanCurrency = (text: string, dontRemoveMinusSign?: boolean) => {
  if (!isNullOrUndefined(text)) {
    try {
      if (dontRemoveMinusSign) {
        return (
          text
            .replace(/[^0-9.$-]/g, "")
            // eslint-disable-next-line no-plusplus, no-param-reassign
            .replace(
              /[$]/g,
              (
                (i) => (m: string) =>
                  !i++ ? m : ""
              )(0)
            )
            // eslint-disable-next-line no-plusplus, no-param-reassign
            .replace(
              /[.]/g,
              (
                (i) => (m: string) =>
                  !i++ ? m : ""
              )(0)
            )
            .replace(/[$]/g, (c, i) => (i !== 0 ? "" : c))
            .replace(/^\./, "")
            .replace(/^\$\./, "$")
            .replace(/\.(\d\d)\d?$/, ".$1")
        );
      }
      return (
        text
          .replace(/[^0-9.$]/g, "")
          // eslint-disable-next-line no-plusplus, no-param-reassign
          .replace(
            /[$]/g,
            (
              (i) => (m: string) =>
                !i++ ? m : ""
            )(0)
          )
          // eslint-disable-next-line no-plusplus, no-param-reassign
          .replace(
            /[.]/g,
            (
              (i) => (m: string) =>
                !i++ ? m : ""
            )(0)
          )
          .replace(/[$]/g, (c, i) => (i !== 0 ? "" : c))
          .replace(/^\./, "")
          .replace(/^\$\./, "$")
          .replace(/\.(\d\d)\d?$/, ".$1")
      );
    } catch (e) {
      return text;
    }
  }
  return text;
};

export const clearCurrencyV2 = (text: string) => {
  if (!isNullOrUndefined(text)) {
    return text.replace(/[$,]/g, '');
  }
  return text;
}

export const isValidCurrency = (text: string) => {
  return /^\${0,1}[0-9]\d*(((,\d{3}){1})*(\.\d{1,2})?)$/.test(text);
};

export const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD"
});

export const sanitizeCurrency = (text: string) => {
  try {
    const result = text?.replace(/\.$/, "");
    return currencyFormatter.format(
      Number.parseFloat(result.replace(/[^0-9.]/g, ""))
      // /**
      //  * negative currency should be allowed. as per LPP-2636
      //  */
      // Number.parseFloat(result)
    );
  } catch (e) {
    // console.error(e);
    return text;
  }
};

export const sanitizeCurrencyWithMinus = (text: string) => {
  try {
    const result = text?.replace(/\.$/, "");
    return currencyFormatter.format(
      // Number.parseFloat(result.replace(/[^0-9.]/g, ""))
      // /**
      //  * negative currency should be allowed. as per LPP-2636
      //  */
      Number.parseFloat(result)
    );
  } catch (e) {
    // console.error(e);
    return text;
  }
};

export function sanitizePercentage(
  text: any,
  multiplyBy100?: boolean,
  deci: number = 4
) {
  if (text || text === 0) {
    let textToChange = text;
    let result = textToChange;
    if (typeof result === "string" && result.includes("%"))
      result = result.replace("%", "");
    if (multiplyBy100) {
      const percentage = typeof text === "number" ? text : Number(text);
      textToChange = isNaN(percentage)
        ? `${text}`
        : `${(percentage * 100).toFixed(deci)}`;
      result = textToChange.replace(/\.$/, "").replace(/%/, "");
    } else {
      const temp = Number(result);
      if (!isNaN(temp)) result = temp.toFixed(deci);
    }
    return `${result}%`;
  }
  return text;
}
export const isNullOrUndefined = (value: any) => {
  if (value === null || value === undefined) {
    return true;
  }
  return false;
};
export function formatValueByType(value: any, fieldType?: string, isDecimalFixedTo4?: any) {
  try {
    switch (fieldType) {
      case "string":
        return isEmptyValue(value) ? value : `${value}`;
      case "percentage":
      case "percentageWithMinus": {
        const formattedValue = sanitizePercentage(value, true);
        return isValidPercentage(formattedValue) ? formattedValue : value;
      }
      case "percentageTwoDecimal": {
        const formattedValue = sanitizePercentage(value, true, 2);
        return isValidPercentage(formattedValue) ? formattedValue : value;
      }
      case "percentageToFloat": {
        try {
          const temp = value.replace("%", "");
          if (isNaN(temp)) {
            return temp;
          }
          return Number.parseFloat(temp) / 100;
        } catch (e) {
          console.error(e, value, fieldType);
          return value;
        }
      }
      case "percentageNoWithout100x":
      case "percentageWithout100x": {
        const formattedValue = sanitizePercentage(value, false);
        return isValidPercentage(formattedValue) ? formattedValue : value;
      }
      case "currency":
      case "currencyWithMinus": {
        const formattedValue = sanitizeCurrency(value);
        return isValidCurrency(formattedValue)
          ? Number(value) < 0
            ? formattedValue.replace("$", "$ -")
            : formattedValue
          : value;
      }
      case "decimal": {
        if (value !== "") {
          const number = Number(`${value}`?.replace(/[$,]/g, ''));
          const formattedNumber = number.toFixed(isDecimalFixedTo4 ? 4 : 2);
          return formattedNumber;
        }
        return value;
      }
      case "numberString":
      case "number": {
        if (isEmptyValue(value)) {
          return value;
        }
        const formattedValue = Number(value);
        if (isNaN(formattedValue)) {
          return value;
        }
        return formattedValue;
      }
      case "MMMDDYYYYDate": {
        return MMMDDYYYY(value);
      }
      case "decimalFixedto3": {
        return Number.parseFloat(value).toFixed(3);
      }
      case "decimalFixedto4": {
        return Number.parseFloat(value).toFixed(4);
      }
      case "checkbox": {
        return value?.toString() ?? "true";
      }
      case "booleanDropDown": {
        return value.toLowerCase() === "yes" || value.toLowerCase() === "y" ? true : false;
      }
      default:
        return value;
    }
  } catch (error) {
    console.error(error, value);
    return value;
  }
}
export function formatNumberToCommaSeparated(val: any) {
  if (isEmptyValue(val)) {
    return null;
  }
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export const getParticularPropertyIndex = (
  evaluationResults: EvaluationResultStore["loanEvaluationResult"],
  fieldName: string,
  loanId: string,
  prevObjValue?: any
) => {
  const { loanResult, propertiesResults } = evaluationResults;
  let sectionCopy: any = {};

  sectionCopy = JSON.parse(JSON.stringify(loanResult[loanId][fieldName]));

  // removing extra fields are there in loan level but are not present in property level
  switch (fieldName) {
    case "discounts": {
      [
        "medianFico",
        "foreignNationalString",
        "brokerFees",
        "totalMarketDataDeductions",
        "maxLoan750Kto3MM",
        "maxLoan2MMto3MM",
        "maxLoanAmountDiscount",
        "maxLoanSize"
      ].forEach((item: string) => delete sectionCopy[item]);
      break;
    }
    case "maxLeverage":
    case "maxLeverageTest": {
      delete sectionCopy.sectionEligibility;
      break;
    }
    case "loanCharacterisations": {
      sectionCopy = {};
      sectionCopy.rehabType = prevObjValue.rehabType;
    }
  }
  const propertiesResultsArray = Object.keys(propertiesResults).map(
    (key, i) => {
      // comparing the loan level object to property level objects
      if (isEquivalentObjects(sectionCopy, propertiesResults[key][fieldName]))
        return i + 1;
      return 0;
    }
  );
  // if returned value is ZERO, it means this property didn't match
  const foo = propertiesResultsArray.filter((item) => item > 0);
  // if returned value is ZERO, it means this property didn't match
  return propertiesResultsArray.length ? (foo.length ? foo[0] : 1) : 0;
};

export function isEquivalentObjects(a: any, b: any) {
  // Create arrays of property names
  const aProps = Object.getOwnPropertyNames(a || {});
  const bProps = Object.getOwnPropertyNames(b || {});

  // If number of properties is different,
  // objects are not equivalent
  if (aProps.length !== bProps.length) {
    return false;
  }

  for (let i = 0; i < aProps.length; i++) {
    const propName = aProps[i];

    // If values of same property are not equal,
    // objects are not equivalent
    if (a[propName] !== b[propName]) {
      return false;
    }
  }

  // If we made it this far, objects
  // are considered equivalent
  return true;
}
export function isEmptyValue(inputValue: any) {
  let value = inputValue;
  if (typeof value === "string") value = value.trim();
  return value === "" || isNullOrUndefined(value);
}
export function isValidNumber(value: any) {
  if (isEmptyValue(value)) {
    return false;
  }
  const param =
    typeof value === "string" ? Number(value.replace(/\,/g, "")) : value;
  if (isNaN(param)) {
    return false;
  }
  return Number(param) >= 0 && Number.isInteger(Number(param));
}
export function getNumFromCommaSeparatedNum(value: any) {
  return typeof value === "string"
    ? Number(value.replace(/\,/g, ""))
    : Number(value);
}
export function checkFieldValidity(
  value: any,
  type: { fieldTpe: string; extraData?: any }
) {
  try {
    switch (type.fieldTpe) {
      case "currency":
        return isValidCurrency(cleanCurrency(value, true));
      case "percentage": // only positive values
        return isValidPercentage(value, true);
      case "percentageWithNegative":
        return isValidPercentage(cleanPercentage(value), true);
      case "string":
        return !isEmptyValue(value);
      case "number":
      case "numberNoFormat":
      case "numberString":
        return isValidNumber(value);
      case "decimal":
        return isValidDecimal(cleanDecimal(value));
      case "dropDown":
        return type?.extraData?.filter((option: any) => {
          const d = option?.value === value || option === value;
          return d;
        })?.length !== type.extraData.length;
      case "radio":
        return type.extraData && type.extraData.includes(value);
      case "email":
        return isValidEmailFormat(value);
      case "phone":
        return isValidPhoneNumber(value);
      case "date":
        return isValidDate(value);
      case "date-time":
        return isValidDate(value);
      default:
        return true;
    }
  } catch (error) {
    console.error(error, value);
    return true;
  }
}

export const getErrorMessage = (fieldType: string, value?: any) => {
  switch (fieldType) {
    case "date":
      return errorDateText(value);
    case "currency":
      return "Please enter valid currency value";
    case "percentage":
      return "Please enter valid percentage value";
    case "number":
    case "numberString":
      return "Please enter valid number";
    case "dropDown":
      return "This field is required";
    case "radio":
      return "This field is  required";
    default:
      return "Please enter valid value";
  }
};
export const errorDateText = (value: any) => {
  let dateInputGiven = value;
  try {
    if (value) {
      dateInputGiven = value.replace(/[()]/g, "");
    }
  } catch (error) {
    console.error(error);
  }
  return `You have entered ${dateInputGiven}.Please enter date in mm/dd/yyyy format.`;
};
export function formatStringDate(date: any) {
  try {
    if (!date) return "";
    let nDate = date;
    nDate = moment(nDate);
    return nDate.format("YYYY-MM-DD");
  } catch (err) {
    return "";
  }
}

export const sanitizeValueByType = (value: any, type: string) => {
  let sanitisedValue: any = null;
  switch (type) {
    case "number":
    case "numberNoFormat":
    case "numberString":
      sanitisedValue = sanitizeNumericInput(value);
      break;
    case "phone":
      sanitisedValue = sanitizePhoneNumber(value);
      break;
    case "currency":
      sanitisedValue = clearCurrencyV2(value);
      break;
    case "percentageWithout100x":
    case "percentageNoWithout100x":
    case "percentage":
      sanitisedValue = cleanPercentageV2(value);
      break;
    case "decimal":
      sanitisedValue = cleanDecimal(value);
      break;
    case "date":
      sanitisedValue = formatStringDate(value);
      break;
    default:
      sanitisedValue = value;
      break;
  }
  return sanitisedValue;
};
export const isEqual = function (value: any, other: any) {
  // Get the value type
  const type = Object.prototype.toString.call(value);

  // If the two objects are not the same type, return false
  if (type !== Object.prototype.toString.call(other)) return false;

  // If items are not an object or array, return false
  if (["[object Array]", "[object Object]"].indexOf(type) < 0) return false;

  // Compare the length of the length of the two items
  const valueLen =
    type === "[object Array]" ? value.length : Object.keys(value).length;
  const otherLen =
    type === "[object Array]" ? other.length : Object.keys(other).length;
  if (valueLen !== otherLen) return false;

  // Compare two items
  const compare = function (item1: any, item2: any) {
    // Get the object type
    const itemType = Object.prototype.toString.call(item1);

    // If an object or array, compare recursively
    if (["[object Array]", "[object Object]"].indexOf(itemType) >= 0) {
      if (!isEqual(item1, item2)) return false;
    }

    // Otherwise, do a simple comparison
    else {
      // If the two items are not the same type, return false
      if (itemType !== Object.prototype.toString.call(item2)) return false;

      // Else if it's a function, convert to a string and compare
      // Otherwise, just compare
      if (itemType === "[object Function]") {
        if (item1.toString() !== item2.toString()) return false;
      } else if (item1 !== item2) return false;
    }
  };

  // Compare properties
  if (type === "[object Array]") {
    for (let i = 0; i < valueLen; i++) {
      if (compare(value[i], other[i]) === false) return false;
    }
  } else {
    for (const key in value) {
      if (value.hasOwnProperty(key)) {
        if (compare(value[key], other[key]) === false) return false;
      }
    }
  }

  // If nothing failed, return true
  return true;
};

export function toISOLocalDateString(
  date: string | Date,
  dontSplitTillT?: boolean
) {
  if (isEmptyValue(date)) return null;
  try {
    const d = new Date(date);
    const tzoffset = new Date().getTimezoneOffset() * 60000; // offset in milliseconds
    const localISOTime = new Date(d.getTime() - tzoffset)
      .toISOString()
      .slice(0, -1);
    if (dontSplitTillT) {
      return localISOTime;
    }
    return localISOTime.split("T")[0];
  } catch (error) {
    console.error("Error converting date", error);
    return null;
  }
}
export function MMMDDYYYY(date: any) {
  try {
    if (!date) return "";
    let nDate = date;
    if (typeof nDate === "number") {
      nDate = moment(new Date(nDate));
    } else if (typeof nDate === "string") {
      nDate = String(nDate).split("T")[0];
    }
    return moment.utc(nDate).format("MMM DD, YYYY");
  } catch (err) {
    return "";
  }
}
export function getNextMonthFirstDay() {
  const now = new Date();
  let current;
  if (now.getMonth() === 11) {
    current = new Date(now.getFullYear() + 1, 0, 1);
  } else {
    current = new Date(now.getFullYear(), now.getMonth() + 1, 1);
  }
  return toISOLocalDateString(current);
}
export function splitISODateTillT(date: string | Date) {
  if (isEmptyValue(date)) return null;
  try {
    const d = new Date(date);
    const localISOTime = new Date(d.getTime()).toISOString().slice(0, -1);
    return localISOTime.split("T")[0];
  } catch (error) {
    console.error("Error converting date", error);
    return null;
  }
}

/**
 *
 * @param date Change date to UI format,eg; 05 Oct 2021, 13:22
 */
export const formatDatetoUi = (date: any) => {
  if (date) {
    return new Date(date).toLocaleDateString(undefined, {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      year: "numeric",
      month: "short",
      day: "2-digit"
    });
  }
  return "";
};

export const formatDateToUI12hrs = (date: any) => {
  if (date) {
    return new Date(date).toLocaleDateString(undefined, {
      hour12: true,
      hour: "2-digit",
      minute: "2-digit",
      year: "numeric",
      month: "short",
      day: "2-digit"
    });
  }
  return "";
};
export function nameToInitials(fullName: string) {
  if (fullName) {
    const namesArray = fullName.trim().toUpperCase().split(" ");
    if (namesArray.length === 3) {
      return namesArray[0].charAt(0) + namesArray[2].charAt(0);
    }
    if (namesArray.length === 2) {
      return namesArray[0].charAt(0) + namesArray[1].charAt(0);
    }
    return namesArray[0].charAt(0);
  }
  return "";
}

export function yesornToBoolean(value: any) {
  if (value === "Yes" || value === "Y" || value === true) {
    return true;
  }
  if (value === "No" || value === "N" || value === false) {
    return false;
  }
  return null;
}

export function booleanToYorN(value: boolean | null, completeString?: boolean) {
  if (value) {
    return completeString ? "Yes" : "Y";
  }
  if (value === false) {
    return completeString ? "No" : "N";
  }
  return null;
}
export const booleanToRecourseFes = (value: boolean | string | null) => {
  if (value === true || value === "Yes") {
    return "Full Recourse";
  }
  if (value === false || value === "No") {
    return "Non-Recourse";
  }
  return value; // can contain limited recourse or null
};
export function yesOrNoToYorN(value: string | boolean) {
  if (value === "Yes" || value === true) {
    return "Y";
  }
  if (value === "No" || value === false) {
    return "N";
  }
  return null;
}
export function MergeRecursive(oldValue: any, newValue: any) {
  const obj1 = JSON.parse(JSON.stringify(oldValue));
  const obj2 = JSON.parse(JSON.stringify(newValue));
  for (const p in obj2) {
    try {
      // Property in destination object set; update its value.
      if (obj2[p].constructor === Object) {
        obj1[p] = MergeRecursive(obj1[p], obj2[p]);
      } else {
        obj1[p] = obj2[p];
      }
    } catch (e) {
      // Property in destination object not set; create it and set its value.
      obj1[p] = obj2[p];
    }
  }

  return obj1;
}

export function getIsAllowedToEdit(
  loanStage: LoanStage | undefined,
  loanState: LoanStatusEnum,
  isViewMode: boolean | undefined
): boolean {
  const {
    FinalReview,
    DueDiligenceReview,
    Approved,
    InitialReview,
    Submit,
    PurchaseInitiated,
    Rejected
  } = LoanStatusEnum;
  if (isViewMode) return false;
  let allowedLoanStages = [InitialReview, Submit];
  if (isLATUser()) {
    switch (loanStage) {
      case LoanStage.fes:
      case LoanStage.pre:
        allowedLoanStages = [
          FinalReview,
          DueDiligenceReview,
          InitialReview,
          Submit,
          PurchaseInitiated // as per trl - 877
        ];
        break;
      case LoanStage.post:
        allowedLoanStages = [
          FinalReview,
          DueDiligenceReview,
          Approved,
          InitialReview,
          Submit,
          PurchaseInitiated,
          Rejected
        ];
        break;
      default:
        allowedLoanStages = [
          FinalReview,
          DueDiligenceReview,
          Approved,
          InitialReview,
          Submit,
          Rejected
        ];
        break;
    }
  }
  return allowedLoanStages.some((ele) => ele === loanState);
}

export const getAvailableLoanStageByLoanId = (loanId: string) => {
  const cookieValue = getCookie("availableLoanStage");
  const cookieObject = cookieValue ? JSON.parse(cookieValue) : {};
  return cookieObject && cookieObject[loanId];
};
/** This function is used to check if user is viewing previous stage of the loan. If yes, it returns true */
export function isViewOnly(
  loanStage: any,
  loanId: any,
  currentLoanStage?: any
) {
  // for checking in dashboard communication
  if (currentLoanStage && window.location.href.indexOf("tab=0") > -1) {
    return loanStage !== currentLoanStage;
  }
  const availableLoanStage = getAvailableLoanStageByLoanId(loanId);
  let viewMode = false;
  if (availableLoanStage) {
    if (loanStage === LoanStage.fes) {
      if (
        availableLoanStage.includes(LoanStage.pre) ||
        availableLoanStage.includes(LoanStage.post)
      ) {
        viewMode = true;
      }
    } else if (loanStage === LoanStage.tv) {
      if (
        availableLoanStage.includes(LoanStage.pre) ||
        availableLoanStage.includes(LoanStage.post)
      ) {
        viewMode = true;
      }
    } else if (loanStage === LoanStage.pre) {
      if (availableLoanStage.includes(LoanStage.post)) {
        viewMode = true;
      }
    }
  }
  return viewMode;
}

export function formatValueForLoanAndPropertyApi(
  value: any,
  fieldType?: string
) {
  try {
    switch (fieldType) {
      case "string":
        return value;
      case "number":
      case "numberString":
        return !isEmptyValue(value) ? Number(value) : null;
      case "percentageWithout100x":
      case "percentageNoWithout100x":
      case "percentage": {
        return !isEmptyValue(value) ? Number(value.replace("%", "")) : null;
      }
      case "currency": {
        return !isEmptyValue(value) ? value?.replace("$", "") : null;
      }
      default:
        return value;
    }
  } catch (error) {
    console.error(error, value);
    return value;
  }
}

/**
 * enable reason field only for org user if loan state is in due diligence review state
 * @param editedFieldState - set of edited field
 * @param fieldName - current field name
 * @param loanState - loan state from loanDetails store
 * @returns - true if the state in due diligence review
 */
export function enableReasonField(
  editedFieldState: any,
  fieldName: string,
  loanState: any
) {
  if (!editedFieldState.length) return false;
  const { DueDiligenceReview } = LoanStatusEnum;
  const editedField = JSON.stringify(editedFieldState).includes(fieldName);
  const isNotLATUser = isLATUser();
  if (!isNotLATUser && loanState === DueDiligenceReview && editedField) {
    return true;
  }
  return false;
}

export const currencyToNumber = (value: string) => {
  try {
    return value?.replace("$", "").replace(/,/g, "");
  } catch (error) {
    console.error(error);
    return value;
  }
};
export const percentageToNumber = (value: string) => {
  try {
    return value?.replace("%", "")?.replace(/,/g, "") ?? value;
  } catch (error) {
    console.error(error);
    return value;
  }
};
export const getLoanTypeByLoanId = (loanId: string) => {
  try {
    const loanType = getLoanTypeFromLoanId(loanId);
    if (loanType === getLoanType[0].loanCreationName)
      return getLoanType[0].displayValue;
    if (loanType === getLoanType[1].loanCreationName)
      return getLoanType[1].displayValue;
  } catch (error) {
    return "NA";
  }
  return "NA";
};

// to add % in postfix of value and $ in prefix of value for edit history
export const editHistoryValue = (editHistory: any, fieldType: string) => {
  const data: any = [];
  editHistory?.forEach((val: any) => {
    const cloneData: any = JSON.parse(JSON.stringify(val));
    if (fieldType === "percentage") {
      cloneData.preValue = formatValueByType(
        cloneData.preValue,
        "percentageWithout100x"
      );
      cloneData.value = formatValueByType(
        cloneData.value,
        "percentageWithout100x"
      );
    }
    if (fieldType === "currency") {
      cloneData.preValue = formatValueByType(cloneData.preValue, "currency");
      cloneData.value = formatValueByType(cloneData.value, "currency");
    }
    if (fieldType === "boolean") {
      cloneData.preValue = cloneData.preValue?.toString() ?? "true";
      cloneData.value = cloneData.value?.toString();
    }
    data.push(cloneData);
  });
  return data;
};

// get primary borrowerInformation and guarantorInformation
export const getPrimaryData = (data: any, key: string) => {
  let primaryObj: any = {};
  const filterSection = data.filter((item: any) => item.loanUserType === key);
  if (filterSection.length > 1) {
    filterSection.forEach((el: any) => {
      if (el.isPrimary) {
        primaryObj = el;
      }
    });
  } else {
    // eslint-disable-next-line prefer-destructuring
    primaryObj = filterSection[0];
  }
  return primaryObj;
};

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const compareNumbers = (n1: any, n2: any) => {
  n1 = Number(n1);
  n2 = Number(n2);
  if (!isNaN(n1) && !isNaN(n2)) {
    if (n1 > n2) return 1;
    if (n1 === n2) return 0;
    if (n1 < n2) return -1;
  }
  return null;
};
export const compareRuleVersion = (
  v1: string,
  symbol: "<" | ">" | "=",
  v2: string
) => {
  v1 = v1 || "v-07-20"; // for old loans where there is no rule version default to this value.
  v2 = v2 || "v-07-20";
  if (v1 === "v0.0") {
    v1 = "v-07-20"; // hard coding to match the format. After v0.0 all are in v-mm-yy format
  }
  if (v2 === "v0.0") {
    v2 = "v-07-20";
  }
  if (symbol === "=" && v1 === v2) return true;
  const v1Arr = v1.split("-") || [];
  const v2Arr = v2.split("-") || [];
  v1Arr.shift(); // delete first element (v)
  v2Arr.shift();
  // After split and removing first element which will be 'v', arr[0] is month, arr[1] is year in which version is released.if there is arr[3] it will be the version identifier in same month.

  // "v-07-20"<"v-07-21"
  const yearCompare = compareNumbers(v1Arr[1], v2Arr[1]);
  if (yearCompare === -1) {
    if (symbol === "<") return true;
    return false;
  }
  if (yearCompare === 1) {
    if (symbol === ">") return true;
    return false;
  }
  if (yearCompare === 0) {
    const monthCompare = compareNumbers(v1Arr[0], v2Arr[0]);
    if (monthCompare === -1) {
      if (symbol === "<") return true;
      return false;
    }
    if (monthCompare === 1) {
      if (symbol === ">") return true;
      return false;
    }
    if (monthCompare === 0) {
      const montlyVerCompare = compareNumbers(v1Arr[2] || 0, v2Arr[2] || 0);
      if (montlyVerCompare === -1) {
        if (symbol === "<") return true;
        return false;
      }
      if (montlyVerCompare === 1) {
        if (symbol === ">") return true;
        return false;
      }
      if (montlyVerCompare === 0) {
        if (symbol === "=") return true;
        return false;
      }
    }
  }
};

export const getRuleVersion = (loanRuleVersions: any) => {
  if (loanRuleVersions?.length) {
    const ruleVersionObject = loanRuleVersions.find(
      (ele: any) => ele.name === "RULE_VERSION"
    );
    return ruleVersionObject?.version;
  }
  return null;
};

export const getFormatedValue = (value: any, fieldType: string) => {
  if (!value) return "";
  let formatedValue = value;
  const percentageType = ["percentage", "percentageWithMinus"];
  const currencytype = ["currency", "currencyWithMinus"];
  if (percentageType.includes(fieldType)) {
    formatedValue = formatedValue.replace("%", "");
    formatedValue = parseFloat(formatedValue) / 100;
    formatedValue = formatedValue.toString();
  }
  if (currencytype.includes(fieldType)) {
    formatedValue = formatedValue.replace("$", "").replaceAll(",", "");
  }

  if (fieldType === "percentageWithout100x") {
    formatedValue = formatedValue.replace("%", "");
  }
  return formatedValue;
};

export const regex = (fieldType?: string) => {
  let pattern;
  switch (fieldType) {
    case "percentage":
    case "percentageWithout100x":
    case "percentageNoWithout100x":
      pattern = RegExp("^([0-9]+[.]?[0-9]*|[.][0-9]+)%?$");
      break;
    case "currency":
      pattern = RegExp("^[$]?\\s?([0-9]+[.]?[0-9]*|[.][0-9]+)$");
      break;
    case "percentageWithMinus": {
      pattern = RegExp("^[-]?([0-9]+[.]?[0-9]*|[.][0-9]+)?%?$");
      break;
    }
    case "currencyWithMinus":
      pattern = RegExp("^[$]?\\s?[-]?([0-9]+[.]?[0-9]*|[.][0-9]+)?$");
      break;
    case "number":
    case "numberString":
      pattern = RegExp("^([0-9]+[.]?[0-9]*|[.][0-9]+)$");
      break;
    case "uuid":
      pattern =
        /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
      break;
    default:
      pattern = RegExp(".*");
  }
  return pattern;
};

export function convertUSDToNumber(usdString: string): number {
  try {
    // Remove any non-numeric characters except dot
    const numericString = usdString.replace(/[^0-9.]/g, "");

    // Parse the numeric string to a floating-point number
    const number = parseFloat(numericString);
    return number;
  } catch (e) {
    console.error(e);
    return 0;
  }
}

export function isFalsyButNotZero(value: any) {
  return !value && value !== 0;
}

export function clearData(data: any, type: string) {
  switch (type) {
    case "number":
      return !isFalsyButNotZero(data) ? Number(data) : null;
    case "currency":
      return data ? clearCurrencyV2(data) : null;
    case "percentage":
    case "percentageWithout100x":
    case "percentageWithMinus":
      return data ? cleanPercentageV2(data) : null;
    case "percentageNoWithout100x":
      return data ? Number(cleanPercentageV2(data)) : null;
    case "numberString":
      return data ? data.toString() : null;
    default:
      return data;
  }
}

export function formatValueToUIByType(value: any, fieldType?: string, dropDownData?: any, isDecimalFixedTo4?: any) {
  if (
    value === null ||
    value === undefined ||
    value === "" ||
    value === "null"
  ) {
    return "";
  }
  try {
    switch (fieldType) {
      case "string":
        return `${value}`;
      case "percentage":
      case "percentageWithMinus": {
        return `${(parseFloat(value) * 100).toFixed(4)}%`
      }
      case "percentageTwoDecimal": {
        return `${(parseFloat(value) * 100).toFixed(2)}%`
      }
      // case "percentageToFloat": {
      //   try {
      //     const temp = value.replace("%", "");
      //     if (isNaN(temp)) {
      //       return temp;
      //     }
      //     return Number.parseFloat(temp) / 100;
      //   } catch (e) {
      //     console.error(e, value, fieldType);
      //     return value;
      //   }
      // }
      case "percentageNoWithout100x":
      case "percentageWithout100x": {
        return `${(parseFloat(value)).toFixed(4)}%`
      }
      case "currency":
      case "currencyWithMinus": {
        let formattedValue = typeof value === "string" ? value : value.toString();
        formattedValue = formattedValue.replace("$", "").replace(/,/g, "");
        return currencyFormatter.format(Number.parseFloat(formattedValue))
      }
      case "decimal": {
        return value !== "" ? parseFloat(value).toFixed(isDecimalFixedTo4 ? 4: 2) : value;
      }
      case "numberString":
      case "number": {
        return value;
      }
      case "MMMDDYYYYDate": {
        return MMMDDYYYY(value);
      }
      case "checkbox": {
        return value?.toString() ?? "true";
      }
      case "booleanDropDown": {
        return dropDownData.find((item: any) => {
          if (value === true || value === "true" || value === "Y") {
            return item.value === "Yes" || item.value === "Y"
          } else {
            return item.value === "No" || item.value === "N"
          }
        }).value ?? value;
      }
      default:
        return value;
    }
  } catch (error) {
    console.error(error, value);
    return value;
  }
}